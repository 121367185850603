<template>
  <slot />
</template>
<script>
export default {};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body,
html {
  overflow-x: hidden;
}
</style>
