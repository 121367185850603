<template>
  <transition name="fade" appear>
    <div class="modal" v-if="showModal">
      <div class="modal__container" v-click-out-side="hideModal">
        <span class="modal__close _ibg" @click="hideModal"
          ><img src="../assets/icons/x.svg" alt=""
        /></span>
        <div class="modal__body">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";

export default {
  name: "ModalComponent",
  directives: {
    clickOutSide,
  },
  props: {
    showModal: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    hideModal() {
      this.$emit("hideModal", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  transition: 0.4s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;

  &__container {
    position: relative;
  }

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 60px;
    @media (min-width: $tablet + px) {
      height: auto;
    }
    @media (max-width: $tablet + px) {
      margin: 0 auto;
      width: 70%;
      padding: 40px 0;
    }

    @media (max-width: $mobile + px) {
      width: 90%;
      padding: 30px 10px;
      border-radius: 30px;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute !important;
    right: 50px;
    top: 50px;
    width: 30px;
    height: 30px;
    z-index: 22;
    @media (max-width: $tablet + px) {
      top: 30px;
      right: 40px;
      width: 25px;
      height: 25px;
    }
    @media (max-width: $mobileSmall + px) {
      top: 15px;
      right: 15px;
      width: 20px;
      height: 20px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
