<template>
  <div class="personal-account">
    <div class="personal-account__container _container">
      <h1 class="personal-account__title main-title _blue">
        Программа лояльности
      </h1>
      <div v-if="tariffs" class="tariffs__items">
        <div
          v-for="tariff in tariffs"
          class="tariff-item"
          :key="tariff.id"
          v-show="tariff.is_active == '1'"
        >
          <div class="tariff-item__content">
            <div class="tariff-item__top" ref="tariff-top">
              <div class="tariff-item__info">
                <div class="tariff-item__title">Тариф</div>

                <div class="tariff-item__group">
                  <div class="tariff-item__name">
                    {{ tariff.ticket_title[$lang] }}
                  </div>
                  <!-- <router-link
                    to="buy-ticket"
                    @click="openTariff(tariff.id)"
                    class="tariff-item__button tariff-item__button_first base-button"
                    >Купить билет
                  </router-link> -->
                  <span
                    class="desBuy"
                    :class="{ active: activeCart[tariff.id] }"
                    @click.stop="addToCart(tariff)"
                  >
                    <button
                      style="margin: 0 auto"
                      class="
                        tariff-item__button tariff-item__button
                        base-button
                      "
                      v-if="!activeCart[tariff.id]"
                    >
                      {{ $t("basket.addCard") }}
                    </button>
                    <button
                      class="
                        tariff-item__button tariff-item__button
                        base-button
                      "
                      style="margin: 0 auto"
                      v-else
                    >
                      {{ $t("deletCart") }}
                    </button>
                  </span>
                </div>
                <div
                  class="tariff-item__text"
                  v-if="tariff.short_description[$lang]"
                >
                  {{ tariff.short_description[$lang] }}
                </div>
              </div>
            </div>
            <div class="tariff-item__blocks">
              <div class="tariff-item__block" v-if="tariff.adult_actual_price">
                <div class="tariff-item__condition">
                  <div class="tariff-item__type">
                    {{ tariff.adult_ticket[$lang] }}
                  </div>
                  <div class="tariff-item__age">
                    {{ $t("noSalePrice") }}
                    <span class="tariff-item__line"></span>
                  </div>
                </div>
                <div class="tariff-item__prices">
                  <div class="tariff-item__price">
                    {{ tariff.adult_actual_price }} ₸
                  </div>
                  <div class="tariff-item__old-price">
                    {{ tariff.adult_old_price }} ₸
                  </div>
                </div>
              </div>

              <div class="tariff-item__block" v-if="tariff.child_actual_price">
                <div class="tariff-item__condition">
                  <div class="tariff-item__type">
                    {{ tariff.child_ticket[$lang] }}
                  </div>
                  <div class="tariff-item__age">
                    {{ $t("noSalePrice")
                    }}<span class="tariff-item__line"></span>
                  </div>
                </div>
                <div class="tariff-item__prices">
                  <div class="tariff-item__price">
                    {{ tariff.child_actual_price }} ₸
                  </div>
                  <div class="tariff-item__old-price">
                    {{ tariff.child_old_price }} ₸
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion>
            <AccordionItem>
              <template #accordion-header>
                <div class="tariff-item__buttons">
                  <!-- <button @click.stop="addToCart(tariff)"
                          class="tariff-item__button tariff-item__button_second base-button">{{ $t("basket.addCard") }}
                        </button> -->
                  <!-- <span
                    class="
                      tariff-item__button tariff-item__button_second
                      base-button
                    "
                    :class="{ active: activeCart[tariff.id] }"
                    @click.stop="addToCart(tariff)"
                  >
                    <button class="" v-if="!activeCart[tariff.id]">
                      {{ $t("basket.addCard") }}
                    </button>
                    <button class="" v-else>
                      {{ $t("deletCart") }}
                    </button>
                  </span> -->
                  <span
                    class="mobBuy"
                    :class="{ active: activeCart[tariff.id] }"
                    @click.stop="addToCart(tariff)"
                  >
                    <button
                      style="margin: 0 auto"
                      class="
                        tariff-item__button tariff-item__button
                        base-button
                      "
                      v-if="!activeCart[tariff.id]"
                    >
                      {{ $t("basket.addCard") }}
                    </button>
                    <button
                      class="
                        tariff-item__button tariff-item__button
                        base-button
                      "
                      style="margin: 0 auto"
                      v-else
                    >
                      {{ $t("deletCart") }}
                    </button>
                  </span>
                  <button class="tariff-item__more">{{ $t("more") }}</button>
                </div>
              </template>
              <template #accordion-content>
                <div
                  class="tariff-item__description"
                  v-html="tariff.large_description[$lang]"
                ></div>
              </template>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import AccordionItem from "@/components/AccordionItem";
import { useToast } from "vue-toastification";
import Accordion from "@/components/AccordionComponent";
export default {
  components: {
    Accordion,
    AccordionItem,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      tariffs: [],
      bonusTotal: null,
      activeCart: [false],
    };
  },
  computed: {
    ...mapGetters(["getUser", "getTickets", "getIsAuth"]),
    ...mapState(["tickets"]),
  },
  created() {},
  mounted() {
    this.getBonus();
    this.getUserBonus();
  },
  methods: {
    getBonus() {
      axios
        .get("V1/bonus-ticket-list")
        .then((response) => (this.tariffs = response.data.data))

        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    getUserBonus() {
      axios
        .get("V1/user-info", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          this.bonusTotal = response.data.bonus;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    addToCart(tariff) {
      let product = tariff;
      let cartList = JSON.parse(localStorage.getItem("cart_products"));
      if (cartList) {
        if (cartList.length >= 3) {
          if (cartList.find((tar) => tar.id === tariff.id)) {
            this.activeCart[tariff.id] = !this.activeCart[tariff.id];
            this.$store.commit("ADD_TO_CART", { product, count: 1 });
          } else {
            this.toast.warning(`Вы можете выбрать в корзину максимум 3 тарифа`);
            // alert("Вы можете выбрать в корзину максимум 3 тарифа");
          }
        } else {
          if (this.bonusTotal < 1) {
            alert(this.$t("basket.enoughBonuses"));
          } else {
            this.activeCart[tariff.id] = !this.activeCart[tariff.id];
            this.$store.commit("ADD_TO_CART", { product, count: 1 });
          }
        }
      } else {
        if (this.bonusTotal < 1) {
          alert(this.$t("basket.enoughBonuses"));
        } else {
          this.activeCart[tariff.id] = !this.activeCart[tariff.id];
          this.$store.commit("ADD_TO_CART", { product, count: 1 });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.mobBuy {
  display: none;
}
.desBuy {
}

@media (max-width: 1366.98px) {
  .mobBuy {
    display: block;
  }
  .desBuy {
    display: none;
  }
}
</style>
