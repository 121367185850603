<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="dropFile"
    :class="{ 'active-dropzone': active }"
    class="dropzone"
  >
    <label for="dropzonePassport"
      ><span><img src="../assets/icons/upload.svg" alt="" /></span
      >{{ $t("inputs.selectFile") }}</label
    >
    <input
      type="file"
      id="dropzonePassport"
      class="dropzonePassport"
      ref="passportInput"
      @change="selectedPassport"
    />
    <span class="dropzone-text">{{ $t("inputs.dragFile") }}</span>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "PassportDropZone",
  data() {
    return {
      dropzoneFile: "",
      active: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    dropFile(e) {
      this.dropzoneFile = e.dataTransfer.files[0];
      this.$emit("dropPassport", this.dropzoneFile);
    },
    selectedPassport() {
      // Get the selected file
      const selectedFile = this.$refs.passportInput.files[0];

      // Check if a file is selected
      if (selectedFile) {
        // Check if the selected file is an image
        if (selectedFile.type.startsWith('image/')) {
          // If it's an image, emit the event
          this.$emit("selectPassport", selectedFile);
        } else {
          // If it's not an image, you can handle the error or notify the user
          this.toast.error(this.$t('pleaseImg'));
          // Alternatively, you can notify the user through a UI element or any other method.
        }
      } else {
        // this.toast.warning(`No file selected.`);
        // Handle the case where no file is selected
        // Alternatively, you can notify the user through a UI element or any other method.
      }
    },
  },
};
</script>
