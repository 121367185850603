<template>
  <transition name="tab">
    <div class="tab-content" v-if="isSelected">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 60px 0 0 0;
  @media (max-width: 767.98px) {
    padding: 24px 0 0 0;
  }
}

.tab-enter-active,
.tab-leave-active {
  transition: opacity 0.35s ease;
}

.tab-enter-from,
.tab-leave-to {
  opacity: 0;
}
</style>
