<template>
 <main class="content">
   <MainHeader @scroll="scrollTo"></MainHeader>
   <slot @scroll="scrollTo" :links="link"></slot>
 </main>
 <MainFooter @scroll="scrollTo"></MainFooter>
</template>
<script>
import MainFooter from "@/components/MainFooter";
import MainHeader from "@/components/MainHeader";
import { mapState } from "vuex";

export default {
 components: {
   MainHeader,
   MainFooter,
 },
 computed: {
   ...mapState(["scrollElement"]),
 },
 data() {
   return {
     link: null,
   };
 },
 methods: {
   scrollTo(link) {
     this.link = link;
     const offsetTop = document.getElementById(link);
     document.getElementById(link).scrollIntoView({
       top: offsetTop,
       behavior: "smooth",
     });
   },
 },
};
</script>

<style lang="scss">
#app {
 display: flex;
 flex-direction: column;
 min-height: 100vh;
}
body,
html {
 overflow-x: hidden;
}
.content {
 flex: 1 0 auto;
}

.footer {
 flex: 0 0 auto;
}
</style>
