<template>
  <div class="reset-password">
    <div class="reset-password__container _container">
      <h1 class="reset-password__title main-title _blue">
        {{ $t("login.passwordReset") }}
      </h1>
      <div class="reset-password__body">
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          class="reset-password__form"
          v-slot="{ errors }"
        >
          <div class="reset-password__item">
            <label for="password">{{ $t("login.password") }}</label>
            <div
              class="reset-password__password"
              :class="{ _error: errors.hasOwnProperty('password') }"
            >
              <Field
                name="password"
                id="password"
                @input="showError = false"
                v-model="password"
                :type="showInput ? 'text' : 'password'"
                class="reset-password__input main-input"
              />
              <button type="button" @click="showInput = !showInput">
                <span><img src="@/assets/icons/form/eye.svg" alt="" /></span>
              </button>
            </div>
            <transition name="fade">
              <span
                class="error-text"
                v-if="errors.hasOwnProperty('password')"
                >{{ errors.password }}</span
              >
            </transition>
          </div>
          <div class="reset-password__item">
            <label for="password">{{ $t("login.repeatPassword") }}</label>
            <div
              class="reset-password__password"
              :class="{ _error: errors.hasOwnProperty('passwordConfirm') }"
            >
              <Field
                name="passwordConfirm"
                id="password"
                @input="showError = false"
                v-model="passwordConfirm"
                :type="showInput1 ? 'text' : 'password'"
                class="reset-password__input main-input"
              />
              <button type="button" @click="showInput1 = !showInput1">
                <span><img src="@/assets/icons/form/eye.svg" alt="" /></span>
              </button>
            </div>
            <transition name="fade">
              <span
                class="error-text"
                v-if="errors.hasOwnProperty('passwordConfirm')"
                >{{ errors.passwordConfirm }}</span
              >
            </transition>
          </div>

          <div class="reset-password__item">
            <button class="reset-password__btn blue-button" type="submit">
              <span>{{ $t("buttons.save") }}</span>
            </button>
            <transition name="fade">
              <span class="error-message" v-if="showError">{{
                $t("errors.minLenght")
              }}</span>
            </transition>
          </div>
        </Form>
        <div class="reset-password__additional">
          <router-link to="/login" class="reset-password__link">{{
            $t("login.singIn")
          }}</router-link>
        </div>

        <div class="reset-password__bottom">
          <span class="reset-password__text"
            >{{ $t("login.haveAccount") }}
          </span>
          <router-link to="/registration" class="reset-password__link"
            >{{ $t("login.register") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations, mapActions, mapState } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useToast } from "vue-toastification";

export default {
  data() {
    const schema = Yup.object().shape({
      password: Yup.string()
        .min(5, this.$t("errors.minLenghtPassword", { value: 5 }))
        .required(this.$t("errors.required"))
        .label("Password")
        .nullable(),
      passwordConfirm: Yup.string()
        .required(this.$t("errors.required"))
        .oneOf([Yup.ref("password"), null], this.$t("errors.mustMatch"))
        .label("Password")
        .nullable(),
    });
    return {
      email: null,
      password: null,
      passwordConfirm: null,
      schema,
      showInput: false,
      showInput1: false,
      showError: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    Field,
    Form,
  },
  computed: {
    ...mapState(["footer"]),
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_RULE"]),
    ...mapActions(["checkAuth"]),
    onSubmit() {
      let url = window.location.href;
      let paramaters = new URL(url).searchParams;
      var email = paramaters.get("email");
      axios
        .post("https://admin.hawaii.kz/api/V1/reset-password", {
          email: email,
          password: this.password,
          password_conformation: this.passwordConfirm,
        })
        .then((res) => {
          if (res.status === 200) {
            this.toast.success(this.$t("toast1"));
            this.$router.push("login");
          }
        })
        .catch((err) => {
          console.log(err);
          this.showError = true;
        });
    },
    openRulePage(object) {
      this.SET_RULE(object);
    },
  },
};
</script>

<style lang="scss">
.reset-password {
  padding: 50px 0 70px 0;
  @media (max-width: 991.98px) {
    padding: 41px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }

  &__title {
    margin: 0 0 100px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 55px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }

  &__body {
    margin: 0 auto;
    @media (min-width: $tablet + px) {
      max-width: 650px;
      background: #ffffff;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 60px;
      padding: 50px 40px;
    }
  }

  &__form {
    margin: 0 0 50px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 37px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 24px 0;
    }
  }

  &__item {
    position: relative;
    label {
      display: block;
      margin: 0 0 8px 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 136.7%;
      letter-spacing: 0.02em;
      color: #256ed8;
      @media (max-width: $tablet + px) {
        font-size: 17px;
      }
      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 5px 0;
      }
    }

    &:not(:last-child) {
      margin: 0 0 35px 0;
      @media (max-width: $tablet + px) {
        margin: 0 0 27px 0;
      }
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 24px 0;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__link {
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #256ed8;
    margin: 0 0 5px 0;
    @media (max-width: $tablet + px) {
      font-size: 16px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 134.61%;
    letter-spacing: 0.02em;
    color: #256ed8;
    @media (max-width: $tablet + px) {
      font-size: 26px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__additional {
    text-align: center;
    margin: 0 0 80px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 64px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 48px 0;
    }
  }

  &__bottom {
    text-align: center;
  }

  &__socials {
    margin: 24px 0 0 0;
    @media (max-width: $tablet + px) {
      margin: 20px 0 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 16px 0 0 0;
    }
    &_second {
      @media (min-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__social {
    &,
    img {
      display: inline-block;
      border-radius: 50%;
      min-width: 30px;
      min-height: 30px;
      width: 38px;
      height: 38px;
      @media (max-width: $tablet + px) {
        width: 34px;
        height: 34px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 30px;
        height: 30px;
      }
    }

    &:not(:last-child) {
      margin: 0 24px 0 0;
      @media (max-width: $tablet + px) {
        margin: 0 20px 0 0;
      }
      @media (max-width: $mobileSmall + px) {
        margin: 0 16px 0 0;
      }
    }
  }

  &__password {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(37, 110, 216, 0.6);
    padding: 0 24px;
    border-radius: 15px;

    input {
      border: none;
      padding: 0;
    }

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }

      img {
        width: 21.03px;
        height: 13.5px;
        @media (max-width: $mobileSmall + px) {
          width: 16.02px;
          height: 11.67px;
        }
      }
    }
  }

  .checkbox-item {
    &__group {
      display: flex;
      align-items: center;
    }
    &__input {
      position: absolute;
      opacity: 0;
      z-index: 1;
      width: 20px;
      height: 20px;
      @media (max-width: $mobileSmall + px) {
        width: 16px;
        height: 16px;
      }

      &:checked ~ .checkbox-item__icon {
        &::after {
          opacity: 1;
        }
      }
    }

    &__icon {
      background: #ffffff;
      border: 1px solid #256ed8;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $mobileSmall + px) {
        width: 18px;
        height: 18px;
      }

      &::after {
        content: url("@/assets/icons/form/checkmark.svg");
        transition: opacity 0.25s ease 0s;
        opacity: 0;
      }
    }

    label {
      display: inline-block;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #256ed8;
      margin: 0 0 0 12px;
      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 0 8px;
      }
    }
    &__text {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .error-message {
    @media (max-width: $mobileSmall + px) {
      bottom: 10px;
    }
  }
}
</style>
